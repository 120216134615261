@charset "utf-8";

/* tracking intro */
.track_intro {
  width: 100%;
  min-height: 699px;
  margin-top: 16px;
  background-color: $color-white;
  border-radius: $b-radius;
  box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
  min-height: 699px;
  .cont_empty {
    min-height: 699px;
    @include flex-column-all-center;
    .emph_case {
      display: block;
      font-size: 18px;
      color: $color-text2;
      font-weight: $font-bold;
      letter-spacing: 0.18px;
    }
    .img_empty {
      display: block;
      width: 120px;
      height: 120px;
      font-size: 0;
      text-indent: -9999px;
      background: url(../images/img_empty_v2.png) 0 0 no-repeat;
      background-size: 120px 120px;
    }
    .txt_empty {
      display: block;
      margin-top: 20px;
      font-size: 16px;
      font-weight: $font-medium;
      color: $color-point;
      text-align: center;
      line-height: 22px;
      letter-spacing: 0.16px;
    }
  }
}

/* tracking popup */
.pop_wrap_finder {
  .pop_inner {
    /*height:720px;*/
    border-radius: 4px;
    background: $color-white;

    /* ant zorro custom */
    .ant-modal-content {
      box-shadow: none;
      .ant-modal-header {
        padding: 0;
        border: 0 none;
        padding: 40px 40px 20px;
      }
      .ant-modal-body {
        padding: 0 40px 40px;
      }
    }

    .pop_top {
      display: flex;
      justify-content: space-between;
      .tit_popup {
        font-size: 24px;
        font-weight: $font-bold;
        letter-spacing: -0.24px;
        color: #000;
      }
      .btn_close {
        display: block;
        width: 20px;
        height: 20px;
        font-size: 0;
        background: url(../images/btn_close_20.svg) no-repeat;
        background-color: $color-white;
        cursor: pointer;
      }
    }
    .pop_content {
      overflow: hidden;
      width: 100%;
      height: 594px;
      border-radius: 4px;
      background: #e6e6e6;
    }
  }
}

app-tracking-page {
  display: block;
  width: 1720px;
  margin: 0 60px 0;
  padding-bottom: 60px;

  /* tracking search */
  tracking-search {
    display: block;
    .track_search {
      display: flex;
      padding: 32px 40px;
      border-radius: 4px;
      background: $color-white;
      box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
      .temp_row {
        .lab_temp {
          font-size: 16px;
          font-weight: $font-bold;
          color: $color-lnb;
          letter-spacing: 0.16px;
        }
        .inp_temp {
          display: block;
          width: 580px;
          margin-top: 4px;
          .ant-select {
            width: 580px;
          }
        }

        /* 선택전후 자동완성 텍스트 컬러값 동일 */
        .ant-select {
          font-variant: inherit;
          &.ant-select-single:not(.ant-select-customize-input) {
            .ant-select-selection-search {
              & + .ant-select-selection-item,
              .ant-select-selection-placeholder {
                color: $color-point;
              }
            }
          }
        }

        /* inp 2개이상 있을 경우 클래스 */
        .inp_temp.wrap_inp_temp {
          display: flex;
          width: auto;
          .inp_temp_text {
            &:nth-child(1) {
              margin-right: 8px;
              .ant-select {
                width: 200px;
              }
            }
            &:nth-child(2) {
              width: 580px;
            }
          }
        }
      }
      .txt_bar {
        @include txt-bar;
        padding-top: 35px;
      }
      .btn_track {
        float: right;
        width: 140px;
        height: 45px;
        margin: 21px 0 0 auto;
      }
    }
  }

  /* tracking info */
  tracking-info {
    display: block;
    .track_info {
      position: relative;
      margin-top: 20px;
      box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
      .track_info_top {
        /* height:337px; */
        padding: 0 40px 0;
        border-radius: 4px 4px 0px 0px;
        background: linear-gradient(139deg, rgba(140, 88, 252, 1), rgba(92, 87, 221, 1));
        .tit_info {
          @include title-text;
          padding: 60px 0 0;
          color: $color-white;
        }
        .wrap_info {
          @include flex-spacebetween;
          padding: 12px 0;
          .info_number {
            font-size: 18px;
            font-weight: $font-bold;
            letter-spacing: -0.18px;
            color: #ededff;
            .txt_flag {
              @include txt_flag;
              margin-right: 12px;
            }
            .txt_bl {
              font-size: 12px;
              font-weight: $font-bold;
              margin-right: 12px;
              padding: 8px 12px;
              border-radius: 4px;
              background: #ededff;
              color: $color-primary;
            }
          }
          .txt_total {
            font-size: 16px;
            font-weight: $font-bold;
            line-height: 28px;
            color: $color-white;
            letter-spacing: -0.14px;
            span {
              font-size: 14px;
              font-weight: $font-bold;
              vertical-align: top;
              letter-spacing: -0.14px;
            }
          }
        }
      }
      .list_info_item {
        position: relative;
        margin-bottom: -70px;
        /* 환적 갯수 리스트 */
        .track_info_item {
          width: 1640px;
          /* padding:0 40px;*/
          margin-top: 16px;
          border-radius: 0 0 4px 4px;
          box-shadow: 0 15px 15px 1px #d8d8df;
          .track_cont_top {
            @include flex-spacebetween;
            padding: 20px 30px 20px 40px;
            background-color: $color-background;
            border-radius: 4px 4px 0 0;
            .tit_top {
              font-size: 16px;
              font-weight: $font-bold;
              color: $color-sub;
              letter-spacing: -0.16px;
            }
            .txt_total {
              font-size: 14px;
              font-weight: $font-bold;
              line-height: 16px;
              color: $color-point;
              letter-spacing: -0.14px;
              span {
                font-size: 14px;
                font-weight: $font-bold;
                vertical-align: top;
                letter-spacing: -0.14px;
              }
            }
          }
          .track_cont_bottom {
            padding: 48px 40px;
            background-color: $color-white;
            border-radius: 0 0 4px 4px;
            ul {
              position: relative;
              display: flex;
              li {
                display: flex;
                flex-direction: column;
                flex: 1 1 50%;
                .emph_title {
                  font-size: 18px;
                  font-weight: $font-bold;
                  line-height: 24px;
                  letter-spacing: -0.18px;
                  color: $color-text2;
                }
                .txt_container {
                  margin-top: 4px;
                  font-weight: $font-medium;
                  letter-spacing: -0.15px;
                  color: $color-text-gray;
                }
                .box_flag {
                  display: flex;
                  padding-top: 16px;
                  line-height: 30px;
                  .txt_flag {
                    display: block;
                    width: 78px;
                    height: 30px;
                    font-size: 12px;
                    font-weight: $font-medium;
                    margin-right: 12px;
                    border-radius: $b-radius;
                    background-color: #f3f3f8;
                    text-align: center;
                    color: $color-text-gray;
                  }
                  .txt_date {
                    display: block;
                    font-size: 14px;
                    color: $color-point;
                    letter-spacing: 0.14px;
                  }
                }
                &:first-child::after {
                  content: '';
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  width: 33px;
                  height: 25px;
                  margin: -13px 0 0 -33px;
                  background: url(../images/ico_big_arrow.svg) 0 0 no-repeat;
                }
                &:nth-child(2) {
                  padding-left: 80px;
                }
              }
            }
          }
        }
      }
      .track_info_bottom {
        height: 112px;
        background-color: $color-white;
        border-radius: 0 0 4px 4px;
      }
    }
  }

  /* tracking timeline */
  tracking-timeline {
    display: block;
    .track_time {
      margin-top: 20px;
      padding: 48px 40px;
      border-radius: 4px;
      box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
      background: $color-white;
      .tit_timeline {
        display: block;
        font-size: 24px;
        font-weight: $font-bold;
        color: $color-black;
        letter-spacing: -0.24px;
      }
      .temp_row {
        padding-top: 21px;
        .lab_temp {
          display: block;
          color: $color-lnb;
          font-size: 16px;
          font-weight: $font-bold;
          letter-spacing: 0.16px;
        }
        .inp_temp {
          display: block;
          margin-top: 12px;
          app-select {
            .ant-select {
              width: 100%;
            }
          }
        }
      }
    }
  }

  /* tracking link */
  tracking-link {
    .track_link {
      background: $color-white;
      margin: 16px 0 0;
      padding: 48px 40px;
      border-radius: $b-radius;
      box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
      .tit_link {
        display: block;
        font-size: 24px;
        font-weight: $font-bold;
        color: $color-black;
        letter-spacing: -0.24px;
      }

      /* link 테이블 영역 */
      .tbl_temp {
        margin-top: 20px;
        border-radius: $b-radius;
        border-style: hidden;
        box-shadow: 0 0 0 1px $b-line-gray;
        table {
          table-layout: fixed;
          a {
            text-decoration: underline;
            text-underline-offset: 5px;
            color: $color-text4;
            letter-spacing: 0.15px;
          }
          thead {
            th {
              font-weight: $font-bold;
              font-size: 16px;
              padding: 28px 16px;
              background: $color-main;
              color: $color-text2;
              text-align: left;
              letter-spacing: 0.16px;
            }
          }

          tbody {
            tr {
              td {
                padding: 28px 16px;
                border-top: 1px solid $b-line-gray;
                border-right: 1px solid $b-line-gray;
                color: $color-text4;
                letter-spacing: 0.15px;
                /* td내에 link_cell을 가지고 있지 않은 경우 병합을 위한 라인 제거 */
                &:not(:has(.link_cell)) {
                  border-top: 0 none;
                }
                &:has(span) {
                  border-top: 1px solid $b-line-gray !important;
                }
                &:last-child {
                  border-right: 0 none;
                }
                span {
                  letter-spacing: 0.15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* timeline-list 전체 공통 */
.wrap_timeline {
  margin-top: 16px;
  padding: 40px;
  border-radius: 4px;
  border: 1px solid $b-line-gray;
  /* 날짜 공통 */
  .txt_date {
    font-size: 15px;
    letter-spacing: -0.15px;
  }
  .box_flag {
    .txt_flag {
      @include txt_flag;
      margin-right: 10px;
    }
    .txt_date {
      font-weight: $font-bold;
      color: $color-text-gray;
    }
    .emph_timeline {
      display: block;
      margin-top: 8px;
      font-size: 22px;
      font-weight: $font-bold;
      color: $color-primary;
      letter-spacing: 0.22px;
    }
  }

  .track_status_area {
    margin-top: 28px;
    .list_status {
      padding-left: 36px;
      border-left: 1px solid $color-text;
      tracking-timeline-item {
        display: block;
        margin-top: 8px;
        &:first-child {
          margin-top: 0;
        }
      }
      li {
        position: relative;
        @include flex-spacebetween;
        padding: 28px;
        border: 1px solid $b-line-gray;
        border-radius: 4px;
        color: $color-point;

        .btn_shared_gray {
          @include btn-purple-line;
          font-size: 14px;
          margin-top: 12px;
          padding: 14px 19px;
          cursor: pointer;
          .ico_arrow {
            @include btn_arrow;
            margin-left: 4px;
            transform: rotate(45deg);
          }
          &:hover {
            background-color: transparent;
            box-shadow: 0px 0px 8px 2px rgba(62, 54, 238, 0.52);
          }
        }
        .txt_date {
          text-align: right;
        }

        /* 좌측 status dott default */
        .ico_dott {
          position: absolute;
          left: -41px;
          top: -1px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $color-text;
        }

        /* 완료되었을때 timeline-list status */
        &.done {
          border: 1px solid $color-text;
          box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
          span:not(.txt_date),
          p {
            color: #333337;
          }
          .txt_date {
            color: $color-text-gray;
          }
          .wrap_time {
            .txt_duration {
              color: #f73d3f;
            }
          }
          /*
						시안 요청 상태
						완료 상태일 경우 departed 영역처리
					*/

          /* 좌측 status dotted */
          .ico_dott {
            background-color: $color-primary;
          }
          /* 좌측 status line */
          &::before {
            content: '';
            position: absolute;
            left: -38px;
            top: 8px;
            width: 1px;
            height: 100%;
            background-color: $color-primary;
          }
        }

        /* 현재 진행중일 경우 timeline-list status */
        &.on {
          border: 1px solid $color-primary;
          box-shadow: 0 2px 6px 0 rgba(92, 87, 221, 0.35);
          span:not(.txt_date):not(.txt_freetime),
          p {
            color: $color-text2;
            font-weight: $font-medium;
          }
          .txt_date {
            color: $color-text2;
            font-weight: $font-bold;
          }

          .wrap_time {
            .txt_date {
              color: #f73d3f;
            }
            .txt_duration {
              color: #f73d3f;
              span {
                color: #f73d3f;
              }
            }
          }

          /* 좌측 status dotted */
          .ico_dott {
            background-color: $color-primary;
          }
          /* 좌측 status line */
          &::after {
            content: '';
            position: absolute;
            left: -38px;
            top: 8px;
            width: 1px;
            height: 100%;
            background-color: $color-primary;
          }
        }
        .wrap_time {
          .txt_date {
            color: #f73d3f;
          }
        }
        .wrap_location {
          span {
            font-size: 16px;
            letter-spacing: -0.16px;
          }
          .txt_departed {
            font-weight: $font-medium;
            &::before {
              content: 'I';
              display: inline-block;
              padding: 0 9px;
              color: $color-text;
            }
            .txt_city {
              font-weight: $font-bold;
              font-size: 16px;
              padding-right: 9px;
              letter-spacing: -0.16px;
              vertical-align: top;
            }
          }
          p {
            font-weight: $font-medium;
            letter-spacing: -0.15px;
            padding-top: 8px;
          }
        }
        .wrap_time {
          @include flex-column;
          .txt_date {
            font-weight: $font-medium;
            text-align: right;
          }
          .txt_duration {
            display: flex;
            font-size: 14px;
            font-weight: $font-medium;
            background: rgba(247, 61, 63, 0.12);
            margin-top: 15px;
            padding: 4px 12px;
            border-radius: 4px;
            letter-spacing: 0.14px;
            color: #f73d3f;
            span {
              display: block;
              padding-left: 5px;
              font-size: 14px;
              font-weight: $font-medium;
              letter-spacing: 0.14px;
            }
            .txt_delay {
              &::after {
                content: 'ㅡ';
                display: inline-block;
                padding: 0 5px;
                color: #f73d3f;
              }
            }
          }
        }
      }

      /* freetime */
      .wrap_freetime {
        @include flex-column;
        .flag_freetime {
          display: flex;
          font-size: 14px;
          background: $color-main;
          margin-top: 8px;
          margin-left: auto;
          padding: 4px 12px;
          border-radius: 4px;
          &.press_time {
            background: #fef1e8;
            .txt_freetime,
            .txt_date {
              color: #f3681a;
            }
          }
          &.over_time {
            background: rgba(247, 61, 63, 0.12);
            .txt_freetime,
            .txt_date {
              color: #f73d3f;
            }
          }
          .txt_freetime,
          .txt_date {
            display: inline-block;
            font-weight: $font-medium;
            padding-left: 5px;
            color: #4d4d51;
            letter-spacing: -0.14px;
          }
          &:has(.txt_expiration) {
            .txt_expiration {
              color: #f73d3f;
            }
          }
        }
      }
    }
  }
}

/* tracking loading */
.loading_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 9998;
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    &::before {
      left: 0;
      width: 40px;
      height: 40px;
    }
  }
}
