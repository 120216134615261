@charset "utf-8";

.wrap_layout {
  display: block;
  width: 1720px;
  margin: 0 60px 0;
  padding-bottom: 60px;
  .cont_layout {
    padding: 48px 40px;
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
  }
}
/* 검색 영역 */
.data_search {
  display: flex;
  background-color: $color-white;
  padding: 32px 40px;
  box-shadow: 0px 2px 8px 0px rgba(219, 219, 227, 0.5);
  border-radius: 4px;
  /* input */
  app-input-text {
    width: 568px;
    margin-right: 8px;
  }

  /* select */
  app-select {
    .ant-select {
      width: 180px;
      margin-right: 8px;
    }
  }

  /* datepicker */
  nz-range-picker {
    width: 280px;
    height: 45px;
    padding: 11px 16px 10px;
    margin-right: 20px;
    border: 1px solid #d3d3e0;
    border-radius: 4px;
    .ant-picker-clear {
      right: 16px;
      top: 51%;
    }
    &:hover {
      border: 1px solid #5c57dd;
    }
  }

  /* 검색버튼 */
  .btn_search {
    width: 160px;
    height: 45px;
    cursor: pointer;
    @include btn-solid2;
    &:hover {
      @include btn-solid2-hover;
    }
  }
}

/* 필터, 버튼, 테이블 */
.wrap_table {
  /* filter 영역 */
  .wrap_filter {
    .list_filter {
      display: flex;
      li {
        display: flex;
        .link_filter {
          display: block;
          min-width: 173px;
          height: 90px;
          padding: 20px 16px;
          margin-right: 8px;
          border: 1px solid #ededff;
          border-radius: 4px;
          background-color: #f5f5ff;
          &:hover {
            background-color: $color-white;
            border: 1px solid #5c57dd;
            box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
            span {
              color: $color-primary;
            }
          }
          &.link_check {
            position: relative;
            font-weight: $font-medium;
            background-color: $color-primary;
            &::after {
              content: '';
              position: absolute;
              right: 14px;
              top: 24px;
              width: 12px;
              height: 12px;
              background: url(../images/ico_check.svg) 0 0 no-repeat;
            }
            span {
              font-size: 15px;
              color: $color-white;
              letter-spacing: 0.15px;
            }
          }
          .txt_num {
            display: block;
            font-weight: $font-bold;
            color: $color-text2;
            letter-spacing: 0.16px;
          }
          .txt_status {
            display: block;
            margin-top: 12px;
            color: #6f6f80;
            letter-spacing: 0.15px;
          }
        }
        &.bar_unsent {
          &::after {
            content: '';
            display: block;
            width: 1px;
            height: 100%;
            margin: 0 12px 0 4px;
            background-color: #dfdfe5;
          }
        }
      }
    }
  }

  /* operator 버튼 영역 */
  .btn_data {
    display: flex;
    margin-right: auto;
    margin-top: 20px;
    button {
      @include btn-static;
      height: 45px;
      font-size: 14px;
      padding: 10px 25px;
      line-height: 22px;
      cursor: pointer;
      letter-spacing: 0.14px;
      &:hover {
        @include btn-hover;
      }
      .ico_comm {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: 3px 4px 0 0;
        vertical-align: top;
      }
    }

    .btn_right {
      margin-left: auto;
      .btn_new {
        margin-left: 8px;
        .ico_plus {
          background: url(../images/ico_plus_16.svg) 0 0 no-repeat;
        }
      }
    }
  }

  /* wms grid 테이블 영역 */
  /* 테이블 영역 */
  .grid_data_table {
    overflow-x: scroll;
    margin-top: 12px;
    border: 1px solid #dfdfe5;
    border-radius: 4px;
    .ant-table-wrapper {
      .ant-table {
        thead {
          tr {
            &:not(:last-child) {
              border-bottom: 1px solid #dfdfe5;
            }
          }
          th {
            background-color: #f3f3f8;
            &::before {
              display: none;
            }
            span {
              font-weight: 600;
            }
          }
        }

        tbody {
          tr {
            &:last-child {
              td {
                border-bottom: 0 none;
              }
            }
            td {
              &.ant-table-cell {
                .btn_order_no {
                  max-width: 140px;
                }
              }
              border-bottom: 1px solid #dfdfe5;
              line-height: 42px;
              letter-spacing: 0.15px;
              /* po,do 라벨 */
              .txt_comment_label {
                float: left;
                padding: 8px 12px;
                border-radius: 4px;
                background-color: #c5c5d8;
                color: #444466;
                line-height: 16px;
                margin: 2px 4px 0 0;
                &.comment_label_po {
                  background-color: #d8d8d8;
                  color: #464646;
                }
              }
              .txt_column {
                @include text-hidden-default;
                line-height: 34px;
                letter-spacing: 0.15px;
              }
              .btn_order_no {
                float: left;
                max-width: 140px;
                font-size: 14px;
                font-weight: $font-medium;
                margin-top: 2px;
                padding: 8px 12px;
                border: 1px solid #d3d3e0;
                border-radius: 4px;
                background: $color-white;
                line-height: 16px;
                letter-spacing: 0.14px;
                cursor: pointer;
                &.active {
                  color: #5c57dd;
                  border-color: #5c57dd;
                }
              }

              .txt_duplicate {
                display: inline-block;
                font-size: 12px;
                font-weight: $font-medium;
                background-color: $color-primary;
                padding: 5px 8px;
                border-radius: 4px;
                color: $color-white;
                line-height: 14px;
              }

              .link_reply {
                position: relative;
                float: left;
                font-size: 14px;
                margin: 0 0 0 5px;
                padding: 0 0 0 17px;
                color: #8b8b9a;
                letter-spacing: 0.14px;
                transition: none;
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 12px;
                  width: 16px;
                  height: 16px;
                  background: url(../images/ico_reply.svg) 0 0 no-repeat;
                }
                &:hover {
                  color: #1c1c20;
                  &::before {
                    background: url(../images/ico_reply_on.svg) 0 0 no-repeat;
                  }
                }
                /* 댓글 99개 이상일 경우 */
                &:is(.num_over) {
                  &::after {
                    content: '+';
                    display: inline-block;
                    color: #8b8b9a;
                  }
                  &:hover {
                    &::after {
                      color: #1c1c20;
                    }
                  }
                }
              }

              /*  Requested Q’ty 셀의 주문 요청수량과 Fulfilled Q’ty 셀의 오차가 있는 경우 */
              &.num_different {
                background-color: #feecec;
              }

              .txt_status {
                font-size: 12px;
                font-weight: $font-medium;
                padding: 5px 8px;
                border-radius: 4px;
                &.received {
                  color: #0f8ce2;
                  background: rgba(26, 157, 247, 0.15);
                }
                &.requested {
                  color: #03bd8d;
                  background: rgba(15, 199, 151, 0.15);
                }
                &.preparing {
                  color: #e69800;
                  background: rgba(236, 170, 0, 0.15);
                }
                &.reported {
                  color: #f73d3f;
                  background: rgba(247, 61, 63, 0.15);
                }
                &.fulfilled {
                  color: #5c57dd;
                  background: rgba(92, 87, 221, 0.15);
                }
                &.canceled {
                  color: #6f6f80;
                  background: #e9e9ed;
                }
                &.delivered {
                  color: #f2751c;
                  background: #ffede0;
                }
              }
            }
          }
        }
      }
    }
  }
}
